import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		meta: {
			title: "Home | Shady Mountain Market"
		},
		component: Home
	},
	{
		path: "/contact-us",
		name: "ContactUs",
		meta: {
			title: "Contact Us | Shady Mountain Market"
		},
		component: () => import("@/views/ContactUs.vue")
	},
	{
		path: "/about-us",
		name: "AboutUs",
		meta: {
			title: "About Us | Shady Mountain Market"
		},
		component: () => import("@/views/AboutUs.vue")
	},
	{
		path: "/made-to-order",
		name: "MadeToOrder",
		meta: {
			title: "Made to Order | Shady Mountain Market"
		},
		component: () => import("@/views/MadeToOrder.vue")
	},
	{
		path: "/baked-goods",
		name: "BakedGoods",
		meta: {
			title: "Baked Goods | Shady Mountain Market"
		},
		component: () => import("@/views/BakedGoods.vue")
	},
	{
		path: "/frozen-dinners",
		name: "FrozenDinners",
		meta: {
			title: "Frozen Dinners | Shady Mountain Market"
		},
		component: () => import("@/views/FrozenDinners.vue")
	},
	{
		path: "/canned-goods",
		name: "CannedGoods",
		meta: {
			title: "Canned Goods | Shady Mountain Market"
		},
		component: () => import("@/views/CannedGoods.vue")
	},
	{
		path: "/directions",
		name: "Directions",
		meta: {
			title: "Directions | Shady Mountain Market"
		},
		component: () => import("@/views/Directions.vue")
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior() {
		return { left: 0, top: 0 };
	},
	routes
});

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "Shady Mountain Market";
	next();
});

export default router;
