<script>
export default {
	props: {
		icon: {
			type: Boolean,
			default: false
		},
		footer: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		winter() {
			let m = new Date().getMonth();
			return m < 3 || m > 9; // Nov - Mar
		}
	}
};
</script>

<style scoped lang="scss">
.hours {
	display: flex;
	flex-direction: row;

	i {
		padding-right: 5px;
	}

	.main {
		width: 160px;
	}

	.footer-holidays {
		width: 220px;
		padding-left: 10px;
	}
}
</style>

<template>
	<div class="hours">
		<i v-if="icon" class="far fa-clock"></i>
		<div v-if="winter">
			<p class="hr-head">WINTER HOURS</p>
			<p class="main">
				NOV-MAR<br />
				MON-SAT 8-5<br />
				SUNDAY CLOSED
			</p>
			<p v-if="!footer">
				Holidays closed:<br />
				New Years Day, Good Friday, Ascension Day, Memorial Day, July 4th, Thanksgiving Day, Christmas Day
			</p>
		</div>
		<div v-else>
			<p class="hr-head">HOURS</p>
			<p class="main">
				APR-OCT<br />
				MONDAY-FRIDAY 8-6<br />
				SATURDAY 8-5<br />
				SUNDAY CLOSED
			</p>
			<p v-if="!footer">
				Holidays closed:<br />
				New Years Day, Good Friday, Ascension Day, Memorial Day, July 4th, Thanksgiving Day, Christmas Day
			</p>
		</div>
		<div v-if="footer" class="footer-holidays">
			<p>
				Holidays closed:<br />
				New Years Day, Good Friday, Ascension Day, Memorial Day, July 4th, Thanksgiving Day, Christmas Day
			</p>
		</div>
	</div>
</template>
