<template>
	<div class="menu-bottomlayer" v-show="mobilemenu" @click="mobilemenu = false"></div>
	<header>
		<nav class="header-links">
			<router-link to="/">HOME</router-link>
			<div class="dropdown" @mouseover="dropdown = true" @mouseleave="dropdown = false">
				<div class="dropdown-button">MARKET</div>
				<div class="dropdown-child" v-show="dropdown">
					<router-link to="/baked-goods">Baked Goods</router-link>
					<router-link to="/frozen-dinners">Frozen Dinners</router-link>
					<router-link to="/canned-goods">Canned Goods</router-link>
				</div>
			</div>
			<router-link to="/about-us">ABOUT US</router-link>
			<router-link to="/directions">DIRECTIONS</router-link>
		</nav>
		<div id="logo">
			<img src="@/assets/images/logo.png" id="logo" />
		</div>
		<div class="hamburger-menu" @click="mobilemenu = !mobilemenu">
			<div>
				<span class="hamburger-menu-bar"></span>
				<span class="hamburger-menu-bar"></span>
				<span class="hamburger-menu-bar"></span>
			</div>
			<div class="hamburger-menu-content" v-show="mobilemenu">
				<ul>
					<li>
						<span class="fa fa-home float-left"></span>
						<router-link to="/">Home</router-link>
					</li>
					<li>
						<span class="fas fa-cookie-bite float-left"></span>
						<router-link to="/baked-goods">Baked Goods</router-link>
					</li>
					<li>
						<span class="fas fa-utensils float-left"></span>
						<router-link to="/frozen-dinners">Frozen Dinners</router-link>
					</li>
					<li>
						<span class="fas fa-apple-alt float-left"></span>
						<router-link to="/canned-goods">Canned Goods</router-link>
					</li>
					<li>
						<span class="fas fa-address-card float-left"></span>
						<router-link to="/about-us">About Us</router-link>
					</li>
					<li>
						<span class="fas fa-map-marker-alt float-left"></span>
						<router-link to="/directions">Directions</router-link>
					</li>
				</ul>
			</div>
		</div>
	</header>
	<a href="/scroll-to-top" v-show="scrollY > 50" @click.prevent="scrollTop" class="return-to-top">
		<i class="fa fa-chevron-up"></i>
	</a>

	<router-view />

	<footer>
		<Hours class="hours" :icon="true" :footer="true" />
		<div class="contact">
			<div class="contact-row">
				<i class="fas fa-map-marker-alt"></i>
				<p>219 Dryville Rd. <br />Fleetwood, PA 19522</p>
			</div>
			<div class="contact-row">
				<i class="fas fa-phone"></i>
				<a href="tel:610-944-9580">(610) 944-9580</a>
			</div>
		</div>
		<div class="email">
			<div class="email-row">
				<i class="fa fa-envelope fa-lg" aria-hidden="true"></i>
				<a href="mailto:shadymountainmarket@gmail.com">shadymountainmarket@gmail.com</a>
			</div>
			<div class="email-row">
				<i class="far fa-copyright" aria-hidden="true"></i>
				<span>2018-2023 Shady Mountain Market</span>
			</div>
		</div>
	</footer>
</template>

<style lang="scss">
html {
	height: 100%;
}

body {
	min-height: 100%;
	flex: 1;
	font-family: bebas-neue;
	max-width: 1200px;
	/* needed for the overlay to extend when you scroll */
	position: relative;
}

html,
body {
	margin: 0 auto;
	padding: 0;
	min-height: 100%;
}

img {
	max-width: 100vw;
}
</style>

<style scoped lang="scss">
.menu-bottomlayer {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

header {
	.header-links {
		display: flex;
		justify-content: center;
		align-items: center;

		color: white;
		background-color: #411211;
		font-family: "futura-pt", "Lato", sans-serif;
		font-size: 17px;

		@media (max-width: 500px) {
			display: none;
		}

		a {
			padding: 20px;
			color: white;
			text-decoration: none;
			&:hover {
				background-color: #e69f9e;
			}
		}

		.dropdown {
			position: relative;

			&-button {
				padding: 20px;
				color: white;
				text-decoration: none;

				cursor: pointer;
			}

			&-child {
				position: absolute;

				display: flex;
				flex-direction: column;

				color: white;
				background-color: #411211;

				width: 200px;
				z-index: 100;
			}
		}
	}

	.hamburger-menu {
		position: fixed;
		background-color: #411211;
		top: 1em;
		left: 1em;
		height: 34px;
		width: 38px;
		z-index: 1000;

		font-family: "futura-pt", "Lato", sans-serif;

		@media (min-width: 501px) {
			display: none;
		}

		&-bar {
			background-color: #fff;
			width: 25px;
			display: block;
			margin: 6px auto;
			height: 3px;
		}

		&-content {
			position: absolute;
			left: 0;
			color: #fff;
			background-color: #411211;
			min-width: 210px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			font-size: 20px;

			a {
				color: #fff;
			}

			ul {
				padding: 10px;
				li {
					display: flex;
					flex-direction: row;
					span {
						width: 35px;
						justify-text: left;
					}
				}
			}
		}
	}
}

.return-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background: rgba(0, 0, 0, 0.7);
	width: 50px;
	height: 50px;
	display: block;
	text-decoration: none;
	border-radius: 35px;
	transition: all 0.3s ease;

	z-index: 100;

	i {
		color: #fff;
		margin: 0;
		position: relative;
		left: 16px;
		top: 13px;
		font-size: 19px;
		transition: all 0.3s ease;
	}

	&:active {
		background: rgba(0, 0, 0, 0.9);

		i {
			color: #fff;
			top: 5px;
		}
	}
}

footer {
	font-family: "futura-pt", sans-serif;
	font-weight: 400;
	font-size: 15px;

	color: #fff;
	background-color: #c2b59c;
	box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.7);
	padding: 15px;

	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		color: #fff;
	}

	.contact {
		&-row {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			i {
				padding-right: 5px;
			}
		}
	}

	.email {
		&-row {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			i {
				padding-right: 5px;
			}
		}
	}

	@media (min-width: 650px) {
		flex-direction: row;
		text-align: left;
		justify-content: space-around;
		align-items: flex-start;

		.hours {
			padding-right: 30px;
		}

		.email {
			align-self: flex-end;
			margin-left: auto;
		}
	}
}
</style>

<script>
import Hours from "@/components/Hours.vue";

import "normalize.css";

export default {
	components: {
		Hours
	},
	data() {
		return {
			mobilemenu: false,
			dropdown: false,

			scrollY: window.pageYOffset
		};
	},
	methods: {
		handleScroll() {
			this.scrollY = window.pageYOffset;
		},
		scrollTop() {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll);
	},
	unmounted() {
		window.removeEventListener("scroll", this.handleScroll);
	}
};
</script>
